<template>
  <div>
    <!-- Navigation -->
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container-fluid mt-4">
      <!-- Kopfzeile mit Werten aus postData -->
      <h3>
        Heti fuvarok
        <small class="text-muted">
          (fuvarszervező: {{ postData.fsz }}, {{ postData.ev }}/{{ postData.het }} hét)
        </small>
      </h3>

      <!-- Tabelle mit Scrollbalken -->
      <div class="table-responsive-wrapper">
        <table class="table table-striped">
          <thead class="sticky-header">

         
              <tr>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.m_datumtol" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.megrendelo" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.viszonylat" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.nagyar" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.kisar" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.felrakas_napja" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.lerakas_napja" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.rendszam" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.potkocsi" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.felsegjel" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.fuvarozo" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.fel_suly" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.le_suly" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.eltero_ar" type="text" class="form-control" />
                  </div>
                </th>
                <th>
                  <div class="input-with-icon">
                    <i class="fas fa-search"></i>
                    <input v-model="filters.eltero_kisar" type="text" class="form-control" />
                  </div>
                </th>
              </tr>



            <tr>
              <th>Megr. dátum</th>
              <th>Megrendelő</th>
              <th>Viszonylat</th>
              <th>Megr. nagyár</th>
              <th>Megr. kisár</th>
              <th>Felrakás napja</th>
              <th>Lerakás napja</th>
              <th>Rendszám</th>
              <th>Potkocsi</th>
              <th>Felségjel</th><th>Fuvarozó</th>
              <th>Fel súly</th>
              <th>Le súly</th>
              <th>Elt.ár</th>
              <th>Száll.kisár</th>
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="truck in filteredTrucks" :key="truck.mellek_az" @click="openTruckDetails(truck)">
              <td>{{ truck.m_datumtol }}</td>
              <td>{{ truck.megrendelo }}</td>
              <td>{{ truck.felrako }} - {{ truck.lerako }} - {{ truck.cikk }}</td>
              <td>{{ truck.nagyar }}</td>
              <td>{{ truck.kisar }}</td>
              <td>{{ truck.felrakas_napja }}</td>
              <td>{{ truck.lerakas_napja }}</td>
              <td>{{ truck.rendszam }}</td>
              <td>{{ truck.potkocsi }}</td>
              <td>{{ truck.felsegjel }}</td>
              <td>{{ truck.fuvarozo }}</td>
              <td>{{ truck.fel_suly }}</td>
              <td>{{ truck.le_suly }}</td>
              <td>
              <input type="checkbox" disabled :checked="truck.eltero_ar === -1" />
            </td> 
              <td>{{ truck.eltero_kisar }}</td>
              
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Zurück-Button -->
      <button class="btn btn-secondary m-3" @click="goBack">← Vissza</button>
      <button class="btn btn-secondary m-3" @click="resetFilters">Szűrők visszaállítása</button>

    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axiosInstance";

export default {
  data() {
    return {
      weeklyTrucks: [], // Liste der wöchentlichen Lieferungen
      filters: {
          m_datumtol: "",
          megrendelo: "",
          viszonylat: "",
          nagyar: "",
          kisar: "",
          felrakas_napja: "",
          lerakas_napja: "",
          rendszam: "",
          potkocsi: "",
          felsegjel: "",
          fuvarozo: "",
          fel_suly: "",
          le_suly: "",
          eltero_ar: "",
          eltero_kisar: "",
        },
    };
  },
  computed: {
    // Hole die postData direkt aus dem Vuex-Store
    postData() {
      return this.$store.getters.getWeekPostData || {};
    },
    filteredTrucks() {
        return this.weeklyTrucks.filter((truck) => {
          return Object.keys(this.filters).every((key) => {
            const filterValue = this.filters[key]?.toLowerCase();
            
            // Für 'viszonylat' die zusammengesetzten Felder kombinieren
            let truckValue = '';
            if (key === 'viszonylat') {
              truckValue = `${truck.felrako || ''} ${truck.lerako || ''} ${truck.cikk || ''}`.toLowerCase();
            } else {
              truckValue = truck[key]?.toString().toLowerCase() || '';
            }

            // Wenn kein Filterwert gesetzt ist, überspringen
            if (!filterValue) return true;

            // Überprüfen, ob der zusammengesetzte Wert den Filter enthält
            return truckValue.includes(filterValue);
          });
        });
      },



  },
  async mounted() {
    this.fetchWeeklyTrucks();
  },
  methods: {
    resetFilters() {
      this.filters = {
        m_datumtol: "",
        megrendelo: "",
        viszonylat: "",
        nagyar: "",
        kisar: "",
        felrakas_napja: "",
        lerakas_napja: "",
        rendszam: "",
        potkocsi: "",
        felsegjel: "",
        fuvarozo: "",
        fel_suly: "",
        le_suly: "",
        eltero_ar: "",
        eltero_kisar: "",
      };
    },

    openTruckDetails(truck){
      this.loadTruckData(truck.m_cegaz, truck.mellek_az);
    },

    async loadTruckData(cegaz, napimellekAz) {//*************************************************************************************** */
    try {
      const postData = {
        ceg_az: cegaz,
        napimellek_az: napimellekAz
      };

      // API-Aufruf zum Laden der Truck-Daten mit `ceg_az` und `napimellek_az`
      const response = await axiosInstance.post('/napi_mellek/list_truck.php', postData);

      // Überprüfen, ob die Antwort erfolgreich ist und Daten vorhanden sind
      if (response.data && response.data.data) {
        ////console.log('Truck-Daten empfangen:', response.data.data[0]);

        // Committe die geladenen Truck-Daten in den Vuex-Store
        this.$store.commit('setTruckData', response.data.data[0]);

        // Navigiere zur entsprechenden Seite: `editTruck` für Bearbeitung oder `newTruck` für neue Lieferungen
        const targetPage =  'editTruck';
        this.$router.push({ name: targetPage });
      } else {
        ////console.error('Fehler beim Laden der Truck-Daten.');
      }
    } catch (error) {
      ////console.error('Fehler beim API-Aufruf:', error);
    }
  },

    async fetchWeeklyTrucks() {
      const postData = this.$store.getters.getWeekPostData;

      if (!postData || !postData.ceg_az || !postData.fsz) {
        console.error("Keine gültigen postData im Store gefunden.");
        return;
      }

      try {
        console.log("Post Data:", postData);
        const response = await axiosInstance.post(
          "/megrendeles/heti_lista_fuvar.php",
          postData
        );

        if (response.status === 200 && response.data && response.data.data) {
          this.weeklyTrucks = response.data.data;
          console.log("Weekly Trucks:", this.weeklyTrucks);
        } else {
          console.error("Fehler beim Abrufen der wöchentlichen Lieferungen:", response);
        }
      } catch (error) {
        console.error("API-Fehler:", error);
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>

.table-responsive-wrapper {
max-height: 700px; /* Maximale Höhe der Tabelle mit Scrollbalken */
overflow-y: auto;  /* Vertikaler Scrollbalken */
position: relative;
}

.sticky-header th {
position: sticky;  /* Sticky macht die Kopfzeile fixiert */
top: 0;            /* Hält die Kopfzeile oben */
background-color: white; /* Hintergrundfarbe der Kopfzeile */
z-index: 2;        /* Sicherstellen, dass die Kopfzeile vor den Daten bleibt */
}

.table {
  min-width: 1500px; /* Erhöhte Mindestbreite für horizontales Scrollen */
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 3px; /* Weniger Padding für kompaktere Darstellung */
  text-align: left;
  border: 1px solid #ddd; /* Rahmen */
  font-size: 12px; /* Kleinere Schriftgröße */
}

h3 {
  margin-bottom: 10px;
}

.input-with-icon {
  position: relative; /* Ermöglicht die absolute Positionierung des Icons */
}

.input-with-icon i {
  position: absolute;
  top: 50%; /* Vertikal zentrieren */
  left: 10px; /* Abstand zum linken Rand */
  transform: translateY(-50%);
  color: #6c757d; /* Graue Farbe für das Icon */
}

.input-with-icon input {
  padding-left: 30px; /* Platz für das Icon schaffen */
}

.input-with-icon input {
  padding-left: 30px; /* Platz für das Icon schaffen */
  font-size: 12px; /* Kleinere Schriftgröße */
  height: 30px; /* Optional: Reduziere die Höhe des Eingabefelds */
}

</style>
