<template>
  <div>
    <!-- Navigation -->
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container-fluid mt-4">
      <!-- Kopfzeile mit Werten aus postData -->
      <h3>
        Heti megrendelések
        <small class="text-muted">
          (fuvarszervező: {{ postData.fsz }}, {{ postData.ev }}/{{ postData.het }} hét)
        </small>
      </h3>

      <!-- Tabelle mit Scrollbalken -->
      <div class="table-responsive-wrapper">
        <table class="table table-striped">
          <thead class="sticky-header">

            <tr>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.m_az" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.megrendelo" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.felrako" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.fhely" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.lerako" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.lhely" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.cikk" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.m_datumtol" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.m_suly" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.m_km" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.hivatkozasi_szam" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.m_aregysegar" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.mar_egysegar" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.gmp" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.megjegyzes" type="text" class="form-control" />
                </div>
              </th>
              <th>
                <div class="input-with-icon">
                  <i class="fas fa-search"></i>
                  <input v-model="filters.belsoHivatkozas" type="text" class="form-control" />
                </div>
              </th>
            </tr>


            <tr>
              <th>VIR AZ</th>
              <th>Megrendelő</th>
              <th>Felrakó</th>
              <th>Felrakó-hely</th>
              <th>Lerakó</th>
              <th>Lerakó-hely</th>
              <th>Cikk</th>
              <th>Dátum</th>
              <th>Súly</th>
              <th>KM</th>
              <th>Hivatkozási szám</th>
              <th>Nagyár</th>
              <th>Kisár</th>
           
              <th>GMP</th> <!-- Neue GMP-Spalte -->
              <th>Megjegyzés</th>
              <th>Belső hivatkozás</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in filteredOrders" :key="order.az" @click="openOrderDetails(order)">
              <td>{{ order.m_az }}</td>
              <td>{{ order.megrendelo }}</td>
              <td>{{ order.felrako }}</td>
              <td>{{ order.fhely }}</td>
              <td>{{ order.lerako }}</td>
              <td>{{ order.lhely }}</td>
              <td>{{ order.cikk }}</td>
              <td>{{ order.m_datumtol }}</td>
              <td>{{ order.m_suly }}</td>
              <td>{{ order.m_km }}</td>
              <td>{{ order.hivatkozasi_szam }}</td>
              <td>{{ order.m_aregysegar }} {{ cleanEgysgarme(order.m_egysegarme) }}</td>
              <td>{{ order.mar_egysegar }} {{ cleanEgysgarme(order.mar_egysegarme) }}</td>
           

              <td>
                <input type="checkbox" disabled :checked="order.gmp === -1" />
              </td> <!-- Häkchen basierend auf GMP-Wert -->

              <td>{{ order.megjegyzes }}</td>
              <td>{{ order.belsoHivatkozas }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Zurück-Button -->
      <button class="btn btn-secondary m-3" @click="goBack">← Vissza</button>
      <button class="btn btn-secondary m-3" @click="resetFilters">Szűrők visszaállítása</button>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axiosInstance";

import { mapMutations } from 'vuex'; // Dies ist der richtige Platz für den Import


export default {
  data() {
    return {
      weeklyOrders: [], // Liste der wöchentlichen Bestellungen
      filters: {
        m_az: "",
        megrendelo: "",
        felrako: "",
        fhely: "",
        lerako: "",
        lhely: "",
        cikk: "",
        m_datumtol: "",
        m_suly: "",
        m_km: "",
        hivatkozasi_szam: "",
        m_aregysegar: "",
        mar_egysegar: "",
        gmp: "",
        megjegyzes: "",
        belsoHivatkozas: "",
      },

    };
  },
  computed: {
    // Hole die postData direkt aus dem Vuex-Store
    postData() {
      return this.$store.getters.getWeekPostData || {};
    },
    filteredOrders() {
      return this.weeklyOrders.filter((order) => {
        return Object.keys(this.filters).every((key) => {
          const filterValue = this.filters[key]?.toLowerCase();
          const orderValue = order[key]?.toString().toLowerCase() || "";

          if (!filterValue) return true;

          return orderValue.includes(filterValue);
        });
      });
    },

  },
  async mounted() {
    this.fetchWeeklyOrders();
  },
  methods: {
    ...mapMutations(['setorderData']), // Nutze mapMutations, um die Mutation in die Komponente zu integrieren
    resetFilters() {
      this.filters = {
        m_az: "",
        megrendelo: "",
        felrako: "",
        fhely: "",
        lerako: "",
        lhely: "",
        cikk: "",
        m_datumtol: "",
        m_suly: "",
        m_km: "",
        hivatkozasi_szam: "",
        m_aregysegar: "",
        mar_egysegar: "",
        gmp: "",
        megjegyzes: "",
        belsoHivatkozas: "",
      };
    },
    openOrderDetails(order) {
    
        const orderData = {
              napifo_az: order.az
        };

            // Speichere die Daten im Store
            this.setorderData(orderData);

    this.$router.push({ name: 'orderDetails' }); // Navigiere zur `orderDetails.vue`
  },

    cleanEgysgarme(value) {
      return value ? value.replace(/Fuvar -/g, "").trim() : value;
    },

    async fetchWeeklyOrders() {
      const postData = this.$store.getters.getWeekPostData;

        if (!postData || !postData.ceg_az || !postData.fsz) {
          console.error('Keine gültigen postData im Store gefunden.');
          return;
        }

      try {
        console.log("Post Data:", postData);
        const response = await axiosInstance.post("/megrendeles/heti_lista.php", postData);

        if (response.status === 200 && response.data && response.data.data) {
          this.weeklyOrders = response.data.data;
          console.log("Weekly Orders:", this.weeklyOrders);
        } else {
          console.error("Fehler beim Abrufen der wöchentlichen Bestellungen:", response);
        }
      } catch (error) {
        console.error("API-Fehler:", error);
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>

.table-responsive-wrapper {
  max-height: 700px; /* Maximale Höhe der Tabelle mit Scrollbalken */
  overflow-y: auto;  /* Vertikaler Scrollbalken */
  position: relative;
}

.sticky-header th {
  position: sticky;  /* Sticky macht die Kopfzeile fixiert */
  top: 0;            /* Hält die Kopfzeile oben */
  background-color: white; /* Hintergrundfarbe der Kopfzeile */
  z-index: 2;        /* Sicherstellen, dass die Kopfzeile vor den Daten bleibt */
}

.table {
  min-width: 1500px; /* Erhöhte Mindestbreite für horizontales Scrollen */
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 3px; /* Weniger Padding für kompaktere Darstellung */
  text-align: left;
  border: 1px solid #ddd; /* Rahmen */
  font-size: 12px; /* Kleinere Schriftgröße */
}

h3 {
  margin-bottom: 10px;
}

.input-with-icon {
  position: relative; /* Ermöglicht die absolute Positionierung des Icons */
}

.input-with-icon i {
  position: absolute;
  top: 50%; /* Vertikal zentrieren */
  left: 10px; /* Abstand zum linken Rand */
  transform: translateY(-50%);
  color: #6c757d; /* Graue Farbe für das Icon */
}

.input-with-icon input {
  padding-left: 30px; /* Platz für das Icon schaffen */
  font-size: 12px; /* Kleinere Schriftgröße */
  height: 30px; /* Optional: Reduziere die Höhe des Eingabefelds */
}

</style>