<template>
  <div>
    <navigation-bar></navigation-bar>
    <div id="app" class="container mt-5">
      <h1>Fuvartervezői rendszer</h1>
    </div>

    <!-- Woche Navigation -->
    <div class="week-navigation">
      <button class="btn btn-danger" @click="previousWeek">Előző hét</button>
      <div class="week-display">{{ year }} - {{ week }}. hét</div>
      <button class="btn btn-success" @click="nextWeek">Következő hét</button>
    </div>

    <!-- Charts im 2x2 Grid -->
<div class="charts-container">
  <div class="chart-item">
    <h3 class="text-center">Napi mennyiségek</h3>
    <canvas id="comparisonChart1"></canvas>
  </div>
  <div class="chart-item">
    <h3 class="text-center">Fuvarszervezői heti mennyiségek</h3>
    <canvas id="comparisonChart2"></canvas>
  </div>
  <div class="chart-item">
    <h3 class="text-center">Saját/alvállalkozói mennyiségek</h3>
    <canvas id="comparisonChart3"></canvas>
  </div>
  <div class="chart-item">
    <h3 class="text-center">Árbevétel (Ft) / Fuvarszervező</h3>
    <canvas id="comparisonChart4"></canvas>
  </div>
</div>



  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { Chart } from 'chart.js/auto';
import NavigationBar from './NavigationBar.vue';
import axiosInstance from '@/axiosInstance';

export default {
  components: {
    'navigation-bar': NavigationBar
  },
 data() {
    return {
      plannedDataForDay: [], // Daten für das erste Chart
      actualDataForDay: [], // Terv_menny für das erste Chart
      plannedDataForFsz: [], // Daten für das zweite Chart
      actualDataForFsz: [], // Daten für die zweite Bar im zweiten Chart
      sajatMennyData: [], // Daten für 'Saját Menny' in der dritten Grafik
      alvMennyData: [], // Daten für 'Alv Menny' in der dritten Grafik
      labels: [], // Wochentage als Labels
      labelsFsz: [], // Fuvarszervező Namen als Labels
      revenueLabels: [],
      revenueData: [],

      year: new Date().getFullYear(),
      week: this.getCurrentWeek(),
      chartInstances: {} // Speichert die Chart-Instanzen
    };
  },
  computed: {
    ...mapGetters(['getUserData']),
    cegAz() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    },
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    }
  },
  mounted() {
    this.fetchDataForCharts();
    this.updateLogParams();
  },
  methods: {
    ...mapMutations(['setLogParams']),
    updateLogParams() {
    const logParams = {
      user: this.fsz,  // oder fsz, je nach Logik
      table_name: '',
      record_id: 0
    };
    this.$store.commit('setLogParams', logParams);
  },
    getCurrentWeek() {
      const today = new Date();
      const oneJan = new Date(today.getFullYear(), 0, 1);
      const firstMonday = new Date(today.getFullYear(), 0, (1 - oneJan.getDay() + 7) % 7 + 1);
      const daysSinceFirstMonday = Math.floor((today - firstMonday) / (24 * 60 * 60 * 1000));
      return Math.ceil((daysSinceFirstMonday + firstMonday.getDay()) / 7);
    },
    async fetchDataForCharts() {
      await this.fetchDataForDayChart();
      await this.fetchDataForFszChart();
      await this.fetchDataForSajatAlvChart(); // Dritte Grafik
      await this.fetchDataForRevenueChart();
      this.renderCharts(); // Render-Charts aufrufen, nachdem alle Daten geladen wurden
    },
    async fetchDataForDayChart() {
      const postData = { ceg_az: this.cegAz, ev: this.year, het: this.week };
      try {
        const response = await axiosInstance.post('/statisztika/stat_day.php', postData);
        if (response.status === 200 && response.data.data) {
          this.processChartDataForDay(response.data.data);
        } else {
          console.error('Fehler beim Abrufen der Daten für das Tageschart:', response);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten für das Tageschart:', error);
      }
    },
    async fetchDataForFszChart() {
      const postData = { ceg_az: this.cegAz, ev: this.year, het: this.week };
      try {
        const response = await axiosInstance.post('/statisztika/stat_fsz.php', postData);
        if (response.status === 200 && response.data.data) {
          this.processChartDataForFsz(response.data.data);
        } else {
          console.error('Fehler beim Abrufen der Daten für das Fuvarszervező Chart:', response);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten für das Fuvarszervező Chart:', error);
      }
    },

    async fetchDataForSajatAlvChart() {
      const postData = { ceg_az: this.cegAz, ev: this.year, het: this.week };
      try {
        const response = await axiosInstance.post('/statisztika/stat_sajatidegen.php', postData);
        if (response.status === 200 && response.data.data) {
          this.processChartDataForSajatAlv(response.data.data);
        } else {
          console.error('Fehler beim Abrufen der Daten für Saját/Alvállalkozói Grafik:', response);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten für Saját/Alvállalkozói Grafik:', error);
      }
    },

    async fetchDataForRevenueChart() {
      const postData = { ceg_az: this.cegAz, ev: this.year, het: this.week };
      try {
        const response = await axiosInstance.post('/statisztika/stat_fszArbevetel.php', postData);
        if (response.status === 200 && response.data.data) {
          this.processChartDataForRevenue(response.data.data);
        }
      } catch (error) {
        console.error('Fehler Umsatz Chart:', error);
      }
    },


    processChartDataForDay(data) {
      this.labels = data.map(item => item.het_napja); // Wochentage als Labels
      this.plannedDataForDay = data.map(item => Number(item.megr_menny)); // Megrendelt mennyiség
      this.actualDataForDay = data.map(item => Number(item.terv_menny)); // Betervezett mennyiség
    },
    processChartDataForFsz(data) {
      this.labelsFsz = data.map(item => item.fuvarszervezo); // Fuvarszervező Namen als Labels
      this.plannedDataForFsz = data.map(item => Number(item.megr_menny)); // Megrendelt mennyiség
      this.actualDataForFsz = data.map(item => Number(item.terv_menny)); // Betervezett mennyiség
    },
    processChartDataForSajatAlv(data) {
      this.labels = data.map(item => item.het_napja); // Wochentage als Labels
      this.sajatMennyData = data.map(item => Number(item.sajat_menny)); // Saját Mengen
      this.alvMennyData = data.map(item => Number(item.alv_menny)); // Alvállalkozói Mengen
    },
    processChartDataForRevenue(data) {
      this.revenueLabels = data.map(item => item.fuvarszervezo);
      this.revenueData = data.map(item => Number(item.arbevetel));
    },


    renderCharts() {
      // Tageschart
      const chartConfigDay = {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            { label: 'Megrendelt mennyiség', data: this.plannedDataForDay, backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1 },
            { label: 'Betervezett mennyiség', data: this.actualDataForDay, backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', borderWidth: 1 }
          ]
        },
        options: { scales: { y: { beginAtZero: true } } }
      };

      // Fuvarszervező Chart
      const chartConfigFsz = {
        type: 'bar',
        data: {
          labels: this.labelsFsz,
          datasets: [
            { label: 'Megrendelt mennyiség', data: this.plannedDataForFsz, backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)', borderWidth: 1 },
            { label: 'Betervezett mennyiség', data: this.actualDataForFsz, backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)', borderWidth: 1 }
          ]
        },
        options: { scales: { y: { beginAtZero: true } } }
      };

      // Saját - Alvállalkozói Chart
      const chartConfigSajatAlv = {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            { label: 'Saját mennyiség', data: this.sajatMennyData, backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)', borderWidth: 1 },
            { label: 'Alvállalkozói mennyiség', data: this.alvMennyData, backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)', borderWidth: 1 }
          ]
        },
        options: { scales: { y: { beginAtZero: true } } }
      };


      // Fuvarszervező ArbevetelChart
      const chartConfigFszArbevetel = {
              type: 'bar',
              data: {
                labels: this.revenueLabels,
                datasets: [
                { 
                  label: 'Árbevétel (Ft)',
                  data: this.revenueData,
                  backgroundColor: 'rgba(0, 0, 139, 0.5)', // Dunkelblau mit 80% Deckkraft
                  borderColor: 'rgba(0, 0, 139, 1)', // Dunkelblau (volle Deckkraft)
                  borderWidth: 1 
                }
                            
                ]
              },
              options: { scales: { y: { beginAtZero: true } } }
      };



      // Zerstöre Charts, bevor sie neu gerendert werden
      this.destroyChart('comparisonChart1');
      this.destroyChart('comparisonChart2');
      this.destroyChart('comparisonChart3');
      this.destroyChart('comparisonChart4');

      // Erstelle neue Chart-Instanzen
      this.chartInstances['comparisonChart1'] = new Chart(document.getElementById('comparisonChart1'), chartConfigDay);
      this.chartInstances['comparisonChart2'] = new Chart(document.getElementById('comparisonChart2'), chartConfigFsz);
      this.chartInstances['comparisonChart3'] = new Chart(document.getElementById('comparisonChart3'), chartConfigSajatAlv);
      this.chartInstances['comparisonChart4'] = new Chart(document.getElementById('comparisonChart4'), chartConfigFszArbevetel);
    },
    destroyChart(chartId) {
      if (this.chartInstances[chartId]) {
        this.chartInstances[chartId].destroy();
        delete this.chartInstances[chartId];
      }
    },
    nextWeek() {
      this.week++;
      this.fetchDataForCharts();
    },
    previousWeek() {
      this.week--;
      this.fetchDataForCharts();
    }
  }
};
</script>

<style scoped>
.charts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 Spalten */
  gap: 20px; /* Abstand zwischen den Charts */
  max-width: 1200px; /* Optionale maximale Breite */
  margin: auto; /* Zentriert die Charts */
}

.chart-item {
  background: #fff; /* Weißer Hintergrund */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

canvas {
  width: 100% !important;
  height: 250px !important; /* Feste Höhe, damit es nicht zu groß wird */
}

.week-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.week-navigation button {
  margin: 0 10px;
}

.week-display {
  margin: 0 10px;
  font-size: 1.2em;
}
</style>
